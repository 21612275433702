<template>
  <div v-loading="loading">
    <div class="main-title">学员管理</div>

    <div class="content-list-page page-content">
      <div class="list-head-box">
        <div class="list-head">
          <p class="list-head-title">温馨提示</p>
          <ul>
            <li>
              分班规则：班级绑定班型后，报名该班型的用户,系统会在次日凌晨2点钟自动将用户分配到该班
            </li>
          </ul>
        </div>

        <div class="course-detail-title content-list-page page-content">
          <div style="padding: 10px">
            <div class="fl-l" style="width: 150px">
              <el-image
                :preview-src-list="[imageUrl + $route.query.pic]"
                :src="imageUrl + $route.query.pic"
                height="80px"
                width="150px"
              />
            </div>

            <div class="fl-l margin-l-sm">
              <p>班级：{{ $route.query.name }}</p>
            </div>
          </div>
        </div>
        <div class="clear"></div>
      </div>

      <div class="margin-sm">
        <el-tabs v-model="type" type="card">
          <el-tab-pane label="学员管理" name="manage">
            <el-select
              v-model="select"
              clearable
              placeholder="请选择"
              size="mini"
              @clear="getStudentData"
            >
              <el-option :value="1" label="已打卡" />
              <el-option :value="2" label="未打卡" />
            </el-select>
            <el-button
              class="margin-l-sm"
              size="mini"
              type="primary"
              @click="getStudentData"
            >
              筛选
            </el-button>
            <el-button
              size="mini"
              type="primary"
              @click="showAddStudentDialog = true"
            >
              新增
            </el-button>

            <span class="margin-l-sm">
              <gift
                v-if="type === 'manage'"
                :class_id="$route.query.id"
                :user-list="multipleSelection"
                field="user_id"
                type="4"
              />
            </span>

            <el-table
              ref="singleTable"
              v-loading="loading"
              :data="tableData"
              :header-cell-style="{ padding: 0 }"
              highlight-current-row
              row-key="id"
              style="width: 100%; background: #fff"
              @selection-change="handleSelectionChange"
            >
              <el-table-column
                :reserve-selection="true"
                type="selection"
                width="55"
              ></el-table-column>
              <el-table-column label="id" prop="id" />
              <el-table-column label="头像" prop="avatar">
                <template slot-scope="scope">
                  <el-avatar :src="scope.row.user_name_avatar_url" />
                </template>
              </el-table-column>
              <el-table-column label="用户id" prop="user_id" />
              <el-table-column label="名称" prop="user_name" />
              <el-table-column label="加入时间" prop="created_at" />
              <el-table-column label="打卡总数" prop="record_count" />
              <el-table-column label="近期打卡时间" prop="record_at" />

              <el-table-column label="操作">
                <template slot-scope="scope">
                  <el-popconfirm
                    title="确定删除此学员吗"
                    @confirm="deleteUser(scope.row)"
                  >
                    <el-button slot="reference" type="text">删除 -</el-button>
                  </el-popconfirm>
                  <el-button type="text" @click="handleReplace(scope.row)">
                    打卡记录
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
            <div class="table-batch">
              <span class="fl-r">
                <el-pagination
                  :current-page="page"
                  :page-size="pageSize"
                  :page-sizes="[10, 20]"
                  :total="total"
                  background
                  layout="total, sizes, prev, pager, next, jumper"
                  small
                  @size-change="setPageSize"
                  @current-change="setPage"
                >
                </el-pagination>
              </span>
              <div style="clear: both"></div>
            </div>
          </el-tab-pane>

          <el-tab-pane label="学员动态" name="moment">
            <el-select
              v-model="momentSelectText"
              clearable
              size="mini"
              @change="momentText = ''"
            >
              <el-option label="UID" value="user_id"></el-option>
              <el-option label="微信昵称" value="name"></el-option>
            </el-select>
            <el-input
              v-model="momentText"
              class="margin-l-sm"
              clearable
              size="mini"
              style="width: 160px"
            />
            <el-button
              class="margin-l-sm"
              size="mini"
              type="primary"
              @click="getMomentData(momentText, 'name')"
              >筛选
            </el-button>
            <span class="margin-l-sm">
              <gift
                v-if="type === 'moment'"
                :class_id="$route.query.id"
                :user-list="multipleSelection"
                field="user_id"
                type="5"
              />
            </span>

            <el-table
              ref="singleTable"
              v-loading="loading"
              :data="tableData"
              :header-cell-style="{ padding: 0 }"
              highlight-current-row
              row-key="id"
              style="width: 100%; background: #fff"
              @selection-change="handleSelectionChange"
            >
              <el-table-column
                :reserve-selection="true"
                type="selection"
                width="55"
              ></el-table-column>
              <el-table-column label="id" prop="id" />

              <el-table-column label="头像">
                <template slot-scope="scope">
                  <el-avatar :src="scope.row.user_name_avatar_url" />
                </template>
              </el-table-column>

              <el-table-column label="用户id" prop="user_id" />

              <el-table-column label="用户名" prop="user_name" />

              <el-table-column label="标题" prop="title" />

              <el-table-column label="预览图">
                <template slot-scope="scope">
                  <el-image
                    v-if="scope.row.type === 1"
                    :preview-src-list="[imageUrl + scope.row.pic]"
                    :src="imageUrl + scope.row.pic"
                    class="table-item-img"
                  />
                  <el-image
                    v-else
                    :preview-src-list="[scope.row.video_pic]"
                    :src="scope.row.video_pic"
                    class="table-item-img"
                  />
                </template>
              </el-table-column>

              <el-table-column label="类型" prop="type">
                <template slot-scope="scope">
                  <span v-if="scope.row.type === 1">图片</span>
                  <span v-else>视频</span>
                </template>
              </el-table-column>

              <el-table-column label="状态" prop="status">
                <template slot-scope="scope">
                  <span v-if="scope.row.status === 1">待审核</span>
                  <span v-if="scope.row.status === 2">审核通过</span>
                  <span v-if="scope.row.status === 3">审核不通过</span>
                </template>
              </el-table-column>

              <el-table-column label="创建时间" prop="created_at" />

              <el-table-column label="操作">
                <template slot-scope="scope">
                  <el-popconfirm
                    title="确定删除吗？"
                    @confirm="delSocial(scope.row)"
                  >
                    <el-link slot="reference" type="primary">删除</el-link>
                  </el-popconfirm>
                  -
                  <el-link type="primary" @click="socialDetail(scope.row)"
                    >详情
                  </el-link>
                  -

                  <el-popover
                    v-model="scope.row.visible"
                    placement="top"
                    width="160"
                  >
                    <div>
                      <el-button
                        size="mini"
                        type="success"
                        @click="checkSocial(scope.row, 2)"
                        >通过
                      </el-button>
                      <el-button
                        size="mini"
                        type="danger"
                        @click="checkSocial(scope.row, 3)"
                        >不通过
                      </el-button>
                    </div>
                    <el-link slot="reference" type="primary">审核</el-link>
                  </el-popover>
                  <span v-if="scope.row.type === 2">
                    -
                    <span>
                      <el-link type="primary" @click="showPlayDialog(scope.row)"
                        >播放</el-link
                      >
                    </span>
                  </span>
                </template>
              </el-table-column>
            </el-table>

            <div class="table-batch">
              <span class="fl-r">
                <el-pagination
                  :current-page="page"
                  :page-size="pageSize"
                  :page-sizes="[10, 20]"
                  :total="total"
                  background
                  layout="total, sizes, prev, pager, next, jumper"
                  small
                  @size-change="setPageSize"
                  @current-change="setPage"
                >
                </el-pagination>
              </span>
              <div style="clear: both"></div>
            </div>
          </el-tab-pane>

          <el-tab-pane label="班级通知" name="notification">
            <div class="padding-b-sm">
              <el-button
                icon="el-icon-plus"
                size="mini"
                type="primary"
                @click="showDialog = true"
              >
                选择学员
              </el-button>

              <div class="student padding-sm" style="margin: 12px 0">
                <div style="color: #606266; line-height: 30px">
                  <span class="fl-l"> 已选学员：</span>

                  <el-tag
                    v-for="student in dialogStu"
                    :key="student.id"
                    class="margin-b-sm margin-r-sm"
                    closable
                    effect="plain"
                    type="success"
                    @close="subStudent(student)"
                  >
                    <div class="fl-l">
                      <div
                        style="
                          display: flex;
                          align-items: center;
                          padding: 5px 0;
                        "
                      >
                        <el-avatar
                          :src="student.user_name_avatar_url"
                          shape="square"
                          size="small"
                        ></el-avatar>
                        <span class="margin-l-sm">{{ student.user_name }}</span>
                      </div>
                    </div>
                  </el-tag>
                  <el-button
                    class="fl-r"
                    icon="el-icon-delete"
                    size="mini"
                    type="primary"
                    @click="dialogStu = []"
                  >
                    清空
                  </el-button>
                  <div class="clear"></div>
                </div>
              </div>

              <el-form ref="form" :model="formData" :rules="rules">
                <el-form-item label="通知类型" prop="type">
                  <el-select v-model="formData.type">
                    <el-option :value="1" label="班级通知" />
                    <!--										<el-option :value="2" label="老师请假通知" />-->
                  </el-select>
                </el-form-item>

                <el-form-item label="老师名称" prop="name">
                  <el-input
                    v-model="formData.name"
                    style="width: 195px"
                  ></el-input>
                </el-form-item>

                <el-form-item label="班级名称" prop="className">
                  <el-input
                    v-model="formData.className"
                    style="width: 195px"
                  ></el-input>
                </el-form-item>

                <el-form-item label="跳转小程序" prop="toApplet">
                  <el-switch
                    v-model="formData.toApplet"
                    :active-value="1"
                    :inactive-value="0"
                  />
                </el-form-item>

                <el-form-item v-show="formData.toApplet" label="小程序路径">
                  <el-input v-model="formData.path" style="width: 195px" />
                </el-form-item>

                <el-form-item label="通知内容" prop="content">
                  <el-input
                    v-model="formData.content"
                    :maxlength="150"
                    :rows="12"
                    clearable
                    show-word-limit
                    type="textarea"
                  />
                </el-form-item>
              </el-form>

              <div
                style="display: flex; justify-content: center; margin-top: 20px"
              >
                <el-button size="mini" type="primary" @click="submit">
                  发送通知
                </el-button>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="分班规则" name="classRule" v-if="$store.state.user.info.role !== 33">
            <class-rule :classId="Number(this.$route.query.id)"></class-rule>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>

    <el-dialog
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
      :visible.sync="showAddStudentDialog"
      append-to-body
      title="添加学员"
      width="50%"
    >
      <div>
        <div class="padding-sm">
          <el-select
            slot="prepend"
            v-model="searchType"
            placeholder="选择类型"
            size="mini"
          >
            <el-option :value="1" label="微信昵称"></el-option>
            <el-option :value="2" label="UID"></el-option>
          </el-select>
          <el-input
            v-model="studentName"
            class="margin-l-sm"
            clearable
            size="mini"
            style="width: 160px"
          ></el-input>

          <el-button
            :loading="searchStudentBtn"
            class="margin-l-sm"
            size="mini"
            type="primary"
            @click="searchStudent"
            >筛选
          </el-button>
        </div>

        <div v-if="notData" class="margin-t-sm">暂无数据</div>
        <div class="margin-t-sm">
          <el-tag
            v-for="student in searchStudents"
            :key="student.id"
            class="margin-b-sm margin-r-sm"
            effect="plain"
          >
            <div style="display: flex; align-items: center; padding: 5px 0">
              <span>UID:{{ student.id }}</span>
              <el-avatar
                :src="student.avatar_url"
                class="margin-l-sm"
                shape="square"
                size="small"
              ></el-avatar>
              <span class="margin-l-sm">{{ student.name }}</span>
              <span
                class="margin-l-sm el-icon-plus icon-hover"
                @click="selectStudents(student)"
              ></span>
            </div>
          </el-tag>
        </div>

        <div v-if="selectStudent.length > 0">
          <p class="margin-t-sm margin-b-sm">已选学员</p>
          <div
            style="
              border: 1px solid #eee;
              padding: 10px 10px 0 10px;
              border-radius: 10px;
            "
          >
            <el-tag
              v-for="student in selectStudent"
              :key="student.id"
              class="margin-b-sm margin-r-sm"
              closable
              effect="plain"
              type="success"
              @close="subStudent(student)"
            >
              <div class="fl-l">
                <div style="display: flex; align-items: center; padding: 5px 0">
                  <el-avatar
                    :src="student.avatar_url"
                    shape="square"
                    size="small"
                  ></el-avatar>
                  <span class="margin-l-sm">{{ student.name }}</span>
                </div>
              </div>
            </el-tag>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="mini" @click="hideAddStudent">取 消</el-button>
        <el-button
          :loading="buttonLoading"
          size="mini"
          type="primary"
          @click="addStudent"
          >确 定</el-button
        >
      </span>
    </el-dialog>

    <Dialog
      :dialogStuOrigin="dialogStuOrigin"
      :showDialog.sync="showDialog"
      @finish="setStudent"
    />
    <el-dialog :visible.sync="dialogPlayVisible" append-to-body title="播放">
      <div v-loading="videoLoading">
        <div style="padding-bottom: 20px">{{ content }}</div>

        <video :src="videoUrl" controls="controls"></video>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import gift from "@/components/gift/index.vue";
import Dialog from "./dialog";

import { config } from "@/js/config";
import ClassRule from "./classRule";

export default {
  components: { ClassRule, Dialog, gift },
  data() {
    return {
      dialogPlayVisible: false,
      videoLoading: false,
      content: "",
      videoUrl: "",
      dialogStu: [],
      momentSelectText: "user_id",
      showDialog: false,
      momentText: "",
      visible: false,
      select: "",
      pageSize: 10,
      page: 1,
      loading: false,
      total: 0,
      imageUrl: config.imageUrl,
      type: "manage",
      buttonLoading: false,
      formData: {
        type: 1,
        name: "",
        toApplet: false,
        content: "",
        className: this.$route.query.name,
        path: "",
      },
      tableData: [],
      rules: {
        type: [{ required: true, trigger: "change", message: "请选择类型" }],
        name: [
          { required: true, trigger: "change", message: "请输入您的名字" },
        ],
        toApplet: [{ required: true, trigger: "change" }],
        className: [{ required: true, trigger: "change" }],
        content: [
          { required: true, trigger: "change", message: "请输入通知内容" },
        ],
      },
      searchStudentBtn: false,
      studentName: "",
      searchType: 1,
      showAddStudentDialog: false,
      searchName: "",
      id: 0,
      searchStudents: [],
      notData: false,
      selectStudent: [],
      setLoading: false,
      workCountLoading: false,
      workCount: 0,
      sendButtonLoading: false,
      dialogStuOrigin: [],
      multipleSelection: [],
    };
  },

  methods: {
    ...mapActions("user", ["getUserList", "getUserSocialDetail"]),
    ...mapActions("common", ["delete", "setStatus"]),

    ...mapActions("classManage", [
      "getClassMessage",
      "getStudentList",
      "getMomentList",
      "addClassNotification",
      "deleteClassUser",
      "addClassUser",
    ]),
    handleSelectionChange(val) {
      console.log(val);
      this.multipleSelection = val;
    },
    async selectStudents(student) {
      if (!this.selectStudent.find((currentId) => +currentId === +student.id)) {
        this.selectStudent.push(student.id);
      }
    },

    submit() {
      this.$refs.form.validate(async (valid) => {
        // if (!valid || !this.dialogStu.length)
        // 	return this.$message.error('请选择所有选项')

        this.loading = true;

        const params = {
          ...this.formData,
          userIds: this.dialogStu.map((item) => item.user_id),
          className: this.$route.query.name,
        };

        if (!params.toApplet) delete params.path;

        const { data } = await this.addClassNotification(params).finally(() => {
          this.loading = false;
        });

        this.$refs.form.resetFields();
        this.dialogStu = [];

        this.$message.success("发送通知成功");

        this.loading = false;
      });
    },
    showAddStudent() {
      this.showAddStudentDialog = true;
    },
    hideAddStudent() {
      this.showAddStudentDialog = false;
    },
    async addStudent() {
      if (!this.selectStudent.length)
        return (this.showAddStudentDialog = false);

      this.buttonLoading = true;

      const { data } = await this.addClassUser({
        user_ids: this.selectStudent,
        class_id: this.$route.query.id,
      });

      data.forEach((id) => this.$message.error("id重复添加", id));

      this.buttonLoading = false;
      this.showAddStudentDialog = false;

      this.getStudentData();
    },

    searchStudent() {
      let searchData = {};
      if (this.searchType === 1) {
        searchData.name = this.studentName;
        searchData.pageSize = 1000;
      } else {
        searchData.uid = this.studentName;
        searchData.pageSize = 1000;
      }
      console.log(searchData);
      if (this.studentName === "") {
        this.$message.error("请输入昵称");
        return;
      }
      this.notData = false;
      this.searchStudentBtn = true;

      this.getUserList(searchData).then((res) => {
        if (res.data.list.length === 0) {
          this.notData = true;
        }
        this.searchStudents = res.data.list;
        this.searchStudentBtn = false;
      });
    },

    subStudent(student) {
      this.selectStudent = this.selectStudent.filter(
        (currentId) => +currentId !== +student
      );
    },

    setPageSize(value) {
      this.pageSize = value;
      if (this.type === "moment") this.getMomentData();
      else this.getStudentData();
    },

    setPage(value) {
      this.page = value;
      if (this.type === "moment") this.getMomentData();
      else this.getStudentData();
    },
    socialDetail(e) {
      this.$router.push("userSocial/detail/" + e.id);
      console.log(e);
    },
    showPlayDialog(row) {
      this.videoLoading = true;
      this.getUserSocialDetail({ id: row.id }).then((res) => {
        console.log(res);
        this.videoUrl = res.data.videoUrl;
        this.content = res.data.content;
        this.videoLoading = false;
      });
      this.dialogPlayVisible = true;
    },
    checkSocial(row, status) {
      let _this = this;
      let data = {
        id: row.id,
        status: status,
        model: "SocialModel",
      };
      this.setStatus(data).then((res) => {
        if (res.ret === 0) {
          this.$message.success("设置成功");
          this.getMomentData();
        }
      });
    },
    delSocial(e) {
      this.delete({ id: e.id, model: "Social" }).then((res) => {
        if (res.ret === 0) {
          this.$message.success("删除成功");
          this.getMomentData();
        }
      });
      console.log(e);
    },
    handleReplace({ user_id }) {
      this.momentText = user_id;
      this.type = "moment";
    },

    async getMessage() {
      // this.loading = true
    },

    async getStudentData() {
      this.loading = true;

      const { res_info, data } = await this.getStudentList({
        class_id: this.$route.query.id,
        record_status: this.select,
        pageSize: this.pageSize,
        page: this.page,
      });

      this.loading = false;

      if (res_info !== "ok") return;

      this.total = data.total;
      this.tableData = data.list;
      this.dialogStuOrigin = data.list;
    },

    async getMomentData() {
      this.loading = true;

      const { data, res_info } = await this.getMomentList({
        [this.momentSelectText]: this.momentText,
        pageSize: this.pageSize,
        page: this.page,
        class_id: this.$route.query.id,
      }).finally(() => {
        this.loading = false;
      });

      this.loading = false;

      if (res_info !== "ok") return;

      this.total = data.total;
      this.tableData = data.list;
    },

    async deleteUser({ id }) {
      this.loading = true;

      await this.deleteClassUser(id);

      this.getStudentData();
    },

    async sendMessage(content) {
      this.sendButtonLoading = true;
    },

    setStudent(stus) {
      this.dialogStu = stus;
      this.showDialog = false;
    },
  },

  created() {
    if (this.$route.query.id) this.getStudentData();
  },

  watch: {
    type(current) {
      this.loading = false;
      this.total = 0;
      this.pageSize = 10;
      this.page = 1;
      this.tableData = [];

      switch (current) {
        case "moment":
          return this.getMomentData();

        case "message":
          return this.getMessage();

        case "manage":
          if (this.$route.query.id) this.getStudentData();
          return;
      }
    },
  },
  beforeDestroy() {
    this.select = "";
  },
};
</script>

<style>
.student {
  border: 1px solid #eee;
}

.icon-hover {
  cursor: pointer;
  font-size: 14px;
}

.icon-hover:hover:before {
  font-size: 14px;
  content: "\e7a0";
}

.el-tag {
  height: auto;
  line-height: auto;
}

.el-tag .el-icon-close {
  top: 5px;
}
</style>