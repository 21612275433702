<template>
	<el-dialog
		title="选择学员"
		append-to-body
		:show-close="false"
		:close-on-press-escape="false"
		:close-on-click-modal="false"
		:visible.sync="showDialog"
		width="50%"
	>
		<div>
			<div>课堂学员：</div>
			<div class="margin-t-sm" v-loading="loading">
				<el-tag
					class="margin-b-sm margin-r-sm"
					effect="plain"
					:type="student.status === 1 ? '' : 'info'"
					:key="student.id"
					v-for="student in dialogStuOrigin"
				>
					<div style="display: flex; align-items: center; padding: 5px 0">
						<el-avatar
							shape="square"
							size="small"
							:src="student.user_name_avatar_url"
						></el-avatar>
						<span class="margin-l-sm">{{ student.user_name }}</span>
						<span
							class="margin-l-sm el-icon-plus icon-hover"
							@click="selectStudents(student)"
						></span>
					</div>
				</el-tag>
			</div>

			<div class="table-batch">
				<span
					><el-button type="primary" size="mini" @click="selectAll"
						>全选</el-button
					></span
				>
				<div style="clear: both"></div>
			</div>

			<div>
				<p class="margin-t-sm margin-b-sm">已选学员</p>
				<div
					style="padding: 10px 10px 0 10px; border-radius: 10px"
					:style="selectStudent.length > 0 ? 'border:1px solid #eee' : ''"
				>
					<el-tag
						type="success"
						closable
						class="margin-b-sm margin-r-sm"
						effect="plain"
						:key="student.id"
						v-for="student in selectStudent"
						@close="subStudent(student)"
					>
						<div class="fl-l">
							<div style="display: flex; align-items: center; padding: 5px 0">
								<el-avatar
									shape="square"
									size="small"
									:src="student.user_name_avatar_url"
								></el-avatar>
								<span class="margin-l-sm">{{ student.user_name }}</span>
							</div>
						</div>
					</el-tag>
				</div>
			</div>
		</div>
		<span slot="footer" class="dialog-footer">
			<el-button @click="$emit('update:showDialog', false)" size="mini"
				>取 消</el-button
			>
			<el-button
				type="primary"
				@click="$emit('finish', selectStudent)"
				size="mini"
				>确 定</el-button
			>
		</span>
	</el-dialog>
</template>

<script>
import { mapActions } from 'vuex'

export default {
	props: {
		showDialog: { default: false },
		dialogStuOrigin: { default: [] }
	},

	data() {
		return {
			loading: false,
			value: '',

			selectStudent: [],
			page: 1,
			pageSize: 10,
			total: 0
		}
	},
	methods: {
		...mapActions('manage', ['getStudentList']),

		subStudent(student) {
			this.selectStudent.splice(this.selectStudent.indexOf(student), 1)
		},
		selectAll() {
			this.dialogStuOrigin.forEach(student => {
				if (
					JSON.stringify(this.selectStudent).indexOf(JSON.stringify(student)) ==
					-1
				) {
					this.selectStudent.push(student)
				}
			})
		},
		selectStudents(student) {
			if (
				JSON.stringify(this.selectStudent).indexOf(JSON.stringify(student)) ==
				-1
			) {
				this.selectStudent.push(student)
			}
		}
	}
}
</script>
