<template>
    <div>
        <div style="display:flex;justify-content:space-between;align-items: center;width: 100%">
            <div ></div>
            <select-check-course @onSelect="onSelect"></select-check-course>
        </div>

        <div class="margin-t-sm padding-t-sm">
            <div>已绑定班型</div>
            <div class="margin-t-sm">
                <el-tag
                        @close="deleted(item.id,index)"
                        size="small"
                        class="margin-r-sm"
                        v-for="(item,index) in checkCourses"
                        :key="index"
                        closable>
                    {{item.name}}
                </el-tag>
            </div>
        </div>

    </div>

</template>

<script>
    import {mapActions} from "vuex";
    import SelectCheckCourse from "../../../components/selectCheckCourse";

    export default {
        name: "classRule",
        components: {SelectCheckCourse},
        props:{
            classId:{
                type:Number,
                default:0
            }
        },
        data(){
            return {
                checkCourses:[],
                checkCourseIds:[]
            }
        },
        methods:{
            ...mapActions('classManage',['setClassRule','getClassRule',]),
            ...mapActions('common',['delete']),
            async deleted(id,index){
                let res = await  this.delete({
                    model:'ClassRule',
                    id:id
                })
                if(res.ret === 0){
                    this.$message.success('删除成功')
                    this.checkCourses.splice(index, 1);
                }
            },
            async getList(){
                let res = await this.getClassRule({classId:this.classId})
                if(res.ret === 0){
                    this.checkCourses = res.data
                }
            },
            async onSelect(checkCourses){
                this.checkCourses = checkCourses[0].data
                this.checkCourseIds = checkCourses[0].ids

                let res = await this.setClassRule({
                    classId:this.classId,
                    checkCourseIds:this.checkCourseIds
                })
                if(res.ret === 0){
                    this.$message.success('绑定成功')
                }
            }
        },
        mounted() {
            this.getList()
        }
    }
</script>

<style >
    .el-tag .el-icon-close{
        top:0 !important;
    }
</style>