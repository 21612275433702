<template>
    <div>
        <div>
            <el-button type="primary" class="select-product-btn" size="mini" @click="dialogVisible = true" plain>绑定班型</el-button><span style="margin-left:10px;font-size: 10px"></span>
        </div>

        <el-dialog
                title="提示"
                :show-close="false"
                :visible.sync="dialogVisible"
                append-to-body
                width="700px"
                :before-close="handleClose">
            <div slot="title">
                添加打卡课程
            </div>

            <div class="file-content1" >
                <div class="all-file">
                    <p class="file-tag " :class="type === 'checkCourse'  ? 'selected' : ''" @click="changeProduct('checkCourse')">打卡课程 <span class="file-count"></span> </p>
                </div>
                <div class="file-list-content">
                    <div style="padding-bottom: 10px">
                        <el-input class="fl-l"
                                  size="mini"
                                  style="width:200px;margin-right: 10px"
                                placeholder="输入名称"
                                v-model="name"
                                clearable>
                        </el-input>
                        <el-button class="fl-l" plain size="mini" @click="search">搜索</el-button>
                        <div class="clear"></div>
                    </div>

                    <div class="file-list" v-loading="loading" >

                        <el-table
                                size="small "
                                :header-cell-style="{padding:0}"
                                :cell-class-name="rowItem"
                                :row-key="getRowKeys"
                                ref="multipleTable"
                                :data="list"
                                tooltip-effect="dark"
                                height="450px"
                                style="width: 100%"
                                @selection-change="handleSelectionChange">
                            <el-table-column
                                    type="selection"
                                    :reserve-selection="true"
                                    width="55">
                            </el-table-column>
                            <el-table-column
                                    prop="name"
                                    label="名称"
                                    width="380" >
                            </el-table-column>
                            <el-table-column
                                    align="right"
                                    label="">
                            </el-table-column>
                        </el-table>
                        <div class="page-info">
                            <div class="fl-l"><span>选择 {{selectCount}} 个</span></div>

                            <div class="fl-r">
                                <el-pagination
                                    background
                                    small
                                    @current-change="setPage"
                                    :page-size="pageSize"
                                    layout="prev, pager, next"
                                    :total="total">
                            </el-pagination>
                            </div>
                            <div class="clear"></div>

                        </div>
                    </div>

                </div>
                <div class="clear"></div>
            </div>

            <span slot="footer" class="dialog-footer">
                <el-button @click="cancel" size="mini">取 消</el-button>
                <el-button type="primary" @click="select" size="mini">确 定</el-button>
          </span>
        </el-dialog>
    </div>
</template>

<script>
    import {mapActions} from "vuex";

    export default {
        name: "selectCheckCourse",
        data() {
            return {
                dialogVisible: false,
                loading:true,
                list:[],
                total:0,
                name:'',
                page:1,
                pageSize:10,
                selectCount:0, //选择数量
                type:'checkCourse',
                selectTableData:[],
                selectData:[] //已经选择的数据
            };
        },
        methods: {
            ...mapActions('course',['courseList']),
            ...mapActions('punch',['getPunchList']),
            handleClose(done) {

            },
            search(){
                this.page = 1
                this.getCheckCourseList({name:this.name})
            },
            changeProduct(type){
                this.$refs.multipleTable.clearSelection()
                console.log(this.selectTableData)
                this.type = type
                switch (type) {
                    case 'course' : this.getList() ; break ;
                    case 'checkCourse' : this.getCheckCourseList() ; break ;
                }
            },
            getRowKeys(row) {
                return row.id;
            },
            setPage(e){
                this.page = e
                switch (this.type) {
                    case 'course' : this.getList() ; break ;
                    case 'checkCourse' : this.getCheckCourseList() ; break ;
                }
            },
            handleSelectionChange(val) {
                this.selectTableData = val;
                this.selectCount = val.length
            },
            rowItem(){
                return 'row-item';
            },
            getList()
            {
                this.loading = true
                let data = {
                    name:this.name,
                    page:this.page,
                    pageSize:this.pageSize
                }

                this.courseList(data).then(res => {
                    if(res.ret === 0)
                    {
                        this.total = res.data.total
                        this.list = res.data.list
                    }
                    this.loading = false
                })
            },
            getCheckCourseList()
            {
                this.loading = true
                let data = {
                    name:this.name,
                    containService:1,
                    page:this.page,
                    pageSize:this.pageSize
                }

                this.getPunchList(data).then(res => {
                    if(res.ret === 0)
                    {
                        this.total = res.data.total
                        this.list = res.data.list
                    }
                    this.loading = false
                })
            },
            cancel()
            {
                this.dialogVisible = false
                this.$refs.multipleTable.clearSelection();
            },
            select(){
                let ids = [];
                let data = []
                this.selectTableData.forEach(item => {

                    ids.push(item.id)
                    data.push({
                        id:item.id,
                        name:item.name
                    })
                })
                let arr = [];
                console.log(ids)
                arr.push({
                    type:this.type,
                    ids:ids,
                    data:data
                })

                console.log(arr)
                this.$emit('onSelect',arr)
                this.dialogVisible = false
            }
        },
        mounted() {
            console.log('获取课程列表')
            this.getCheckCourseList()
        }
    }
</script>

<style>
    .page-info{
        padding:10px 0;
        line-height: 26px;
    }
    .select-file-info{
        padding: 20px;
        background-color: #fafafa;
        width: 160px;
    }
    .select-file-info-name{
        width: 100%;
        color: #333;
        line-height: 20px;
        font-size: 12px;
        word-break:break-all
    }
    .select-file-info-option{

    }
    .el-divider__text, .el-link{
        font-size: 12px;
    }
    .el-dialog__header{
        padding:20px;
    }
    .el-dialog__body{
        padding:0
    }
    .file-content1{
        border-bottom: 1px solid #ebebeb;
        border-top: 1px solid #ebebeb;
    }
    .all-file{
        padding:20px 10px;
        float: left;
        height: 510px;
        overflow-y: scroll;
    }
    .selected{
        background: #f7f7f7;
        color: #0095ff;
    }
    .file-tag{
        height: 32px;
        font-size: 12px;
        padding : 0 10px;
        line-height: 32px;
        cursor: pointer;
        margin-bottom: 10px;
    }
    .file-count{
        margin-left: 5px;
        font-size: 12px;
    }
    .search{
        width: 100%;
        flex-grow: 2;
        padding-bottom:10px
    }
    .file-list-content{
        float: right;
        overflow: hidden;
        padding:10px;
        width: 565px;
    }
    .el-table thead th .cell{
        text-indent:5px
    }
    .file-list{
        /*padding:20px 10px;*/
        /*display: flex;*/
    }
    .el-card__body{
        padding:10px
    }
    .select-icon{
        position: absolute;
        top: 0;
        right: 0;
        width: 32px;
        height: 30px;
        background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAeCAYAAABNChwpAAABS2lUWHRYTUw6Y29tLmFkb2JlLnhtcAAAAAAAPD94cGFja2V0IGJlZ2luPSLvu78iIGlkPSJXNU0wTXBDZWhpSHpyZVN6TlRjemtjOWQiPz4KPHg6eG1wbWV0YSB4bWxuczp4PSJhZG9iZTpuczptZXRhLyIgeDp4bXB0az0iQWRvYmUgWE1QIENvcmUgNS42LWMxMzggNzkuMTU5ODI0LCAyMDE2LzA5LzE0LTAxOjA5OjAxICAgICAgICAiPgogPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4KICA8cmRmOkRlc2NyaXB0aW9uIHJkZjphYm91dD0iIi8+CiA8L3JkZjpSREY+CjwveDp4bXBtZXRhPgo8P3hwYWNrZXQgZW5kPSJyIj8+IEmuOgAAAadJREFUSInF1z1PFEEYAOAHEBIbf8FBQiF/gQIVExYj8Y6Y0HhGqSwEWwu1l2gvhsRGYiRobEDjBxhjZaGUYEzESKWVRqMFCeBZDAYT9nJ7y5J5y5mdeZ/dmZ13t8Vk7RquixStmMAl/IkFgNsYxWYsANzHCNZjAWAep/ArFgBeIcH3IhOd6OTnhWwAeIt+fC0ieVLiRYXLb7IDYBlH8XkvyQc7WRzmzAJ33jcHgE84gpShO/G8zLme3e0DJRYqVBd5sJo+thEAvuAYlupdMLfGvYTT3TttSYmXwyH57Mf6k7eYrGUwgEOYw/G0zpNdPCvT+4jfG6xUw2Ovd+d5AHAQD1FO60xKYb2h8pQna40nbBYA7ZhGNa3z7GE62rj7IdtkeQDQhlu4mGfw/5FlE6bFFsZxMxYAariCq7EA/+IGxuQs50UAYArnsRELADNylPMiAfAYQ5oo50UD4DUG8C0WAN7JWM73CwAr6NOgnO8nwHbyvm1MFABhGfqFr6woAMKGTIQNGgVAeDWHhFc1CoBwSI0I/yBRAITjelQ4vh2IACAUrjH8+Av5AmCiEP+iwQAAAABJRU5ErkJggg==);
    }
    .file-item{
        width: 260px;
        height: 72px;
        margin-bottom: 10px;
        border: 1px solid #ebebeb;
        cursor: pointer;
        position: relative;
        line-height: 1;
    }
    .file-icon{
        float: left;
        width: 50px;
        height: 50px;
    }
    .file-info{
        float: left;
        margin-left:10px
    }
    .file-item-top{
        padding: 12px 10px 12px 12px;
    }
    .file-info-title{
        color: #333;
        margin-bottom: 10px;
        font-size: 12px;
        line-height: 20px;
        width: 170px;
        height: 20px;
        overflow: hidden;
    }
    .file-info-size{
        color: #999;
        font-size: 12px;
    }
    .file-option{
        height: 36px;
        background: #fafafa;
        border-top:1px solid #ebebeb;

    }
    .clear{
        clear: both;
    }
    .el-dialog__footer{
        padding:20px
    }
</style>